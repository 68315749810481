import React from 'react';
import './footer.css';

const socialIcons = [
  {
    icon: "linkedin",
    url: "https://www.linkedin.com/company/modulobytes",
    label: "Follow us on LinkedIn",
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="w-12 h-12 fill-current text-blue-600">
        <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/>
      </svg>
    ),
  },
  {
    icon: "facebook",
    url: "https://www.facebook.com/",
    label: "Follow us on Facebook",
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="w-12 h-12 fill-current text-blue-600">
        <path d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z"/>
      </svg>
    ),
  },
  {
    icon: "instagram",
    url: "https://www.instagram.com/",
    label: "Visit our Instagram",
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="w-12 h-12 fill-current text-pink-600">
        <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/>
      </svg>
    ),
  },
];

function Footer() {
  return (
    <footer>
      <div className="flex justify-around py-4 bg-slate-100 footer_detail">
        <div className="w-1/3 our_value_text">
          <p className="text-2xl font-bold mb-2 text-green-600">Our Values</p>
          <p>
            Our value comes from adding value to our clients, and we are determined to do our work to the best of our abilities.
            We believe in simplicity and quality because software is only worth its mettle if it is simple yet of high quality.
            We offer a wide range of services because our team members bring their expertise and experience from different fields of software development.
            We are always ready to customize our proposals to meet the client's needs. A heart-to-heart conversation is all it takes to get on the same page and reach a win-win solution, so let's have one.
          </p>
        </div>

        <div className="footer_address">
          <p className="font-bold text-2xl mb-2 text-green-600">Get In Touch</p>
          <div className="flex items-center">
            <i className="fa-solid fa-location-dot text-green-600" aria-hidden="true"></i>
            <p className="ml-2">Jaipur, India, 302020</p>
          </div>
          <div className="flex items-center">
            <i className="fa-solid fa-envelope text-green-600" aria-hidden="true"></i>
            <a
              href="mailto:contact@Modulobytes.com"
              className="ml-2 text-blue-600 hover:underline"
              aria-label="Email us at contact@Modulobytes.com"
            >
              contact@Modulobytes.com
            </a>
          </div>

          <div className="flex mt-8 space-x-4">
            {socialIcons.map(({ icon, url, label, svg }) => (
              <a
                href={url}
                key={icon}
                className="social-icon"
                aria-label={label}
                target="_blank"
                rel="noopener noreferrer"
                title={label}
              >
                {svg}
              </a>
            ))}
          </div>
        </div>
      </div>

      <div className="bg-slate-800">
        <p className="copyright_text text-white py-4 text-center">
          Copyright © 2022. All Rights Reserved for Modulobytes
        </p>
      </div>
    </footer>
  );
}

export default Footer;
