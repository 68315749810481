


import React from 'react';
import '../header.css';
import './home.css';

function Home() {
    return (
        <main className="my-20 home_main_container min-h-[30vh]">
            <div className="items-left">
                <h1 className="information_text text-3xl text-zinc-500 text-center border-b-4 border-neutral-200 w-96 m-auto">
                    INFORMATION SERVICES
                </h1>
                <p className="home-text home-text1 mx-auto text-zinc-900 w-2/3 mt-10 text-xl duration-300 text-animation">
                    We are delivery-focused technology experts who can reshape your business
                    process to extract more from existing resources and outperform competitors. 
                    Our teams at Modulobytes possess game-changing skills to harness the 
                    potential of your organization. With higher ethics, integrity, and 
                    professionalism, we are committed to your success. We believe in 
                    corporate symbiosis and strive to see our clients achieve their goals, 
                    ultimately advancing their technological capabilities for the benefit of 
                    their customers.
                </p>
            </div>
        </main>
    );
}

export default Home;
